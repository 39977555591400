/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ol: "ol",
    li: "li",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "We have the right to suspend, revoke or limit your use of a Service if we in our sole discretion determine there is:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a payment overdue by you,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a material breach of your obligations,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "a security breach or security threat posed by you to us, or"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "violation of law."), "\n"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If the cause of the suspension can reasonably be remedied, we will provide notice of the actions you must take to reinstate the Service."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If you fail to take such actions within a reasonable time, we can terminate the Service."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "⏳Failure to pay within thirty (30) days after notice to you of a delayed payment is a material breach."), "\n"), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
